import React from 'react';
import connect from 'Shared/connect';
import { styled } from '@glitz/react';
import { on, Page, scrollPosition } from '@polarnopyret/scope';
import { MainMenuType } from 'Shared/State';
import { isCompact } from 'Shared/Viewport';
import SitePagesType from 'AppShell/Models/SitePages.type';
import MobileHeader, { HEIGHT as mobileHeaderHeight } from './MobileHeader';
import DesktopHeader, { COMPACT_HEIGHT, HEIGHT as desktopHeaderHeight } from './DesktopHeader';
import { showScrollDownHeader, showScrollUpHeader } from './action-creators';
import { showQuickSearch } from 'Search/action-creators';
import * as style from 'Shared/Style';
import { QUICKSEARCH_HEIGHT } from 'Search/QuickSearch';
import { default as currentPageIsStartPage } from 'Start/current-page-is-startpage';
import { default as currentPageIsCmsPage } from 'Cms/current-page-is-cmspage';
import currentPageIsVariation from 'Product/current-page-is-variation';
import currentPageIsGiftCardPage from 'GiftCards/current-page-is-giftcardpage';
import { default as currentPageIsOrderConfirmation } from 'Checkout/Pages/OrderConfirmation/current-page-is-order-confirmation';
import { DesktopTieredModal } from 'SiteLayout/MainMenu';
import { MobileTieredModal } from 'SiteLayout/MainMenu/MobileMainMenu';
import Usp from 'AppShell/Models/Usp.type';
import DesktopSecondaryMenu from 'SiteLayout/MainMenu/DesktopSecondaryMenu';
import QuickSearchResults from 'Search/QuickSearch/QuickSearchResults';
import AddToCartModal from 'Cart/AddToCartModal';
import { SetHeaderHeight } from 'Shared/uiReducer';
import CartModal from 'Cart/CartModal';

export const BLACK_TOP_MOBILE_HEIGHT = 28;
export const BLACK_TOP_DESKTOP_HEIGHT = 32;

type ConnectedPropType = {
  currentBreakpoint: number;
  isCollapsed: boolean;
  isVisible: boolean;
  quickSearchIsOpen: boolean;
  pages: SitePagesType;
  mainMenu: MainMenuType;
  modalIsOpen: boolean;
  showScrollDownHeader: (isMobile: boolean) => void;
  showScrollUpHeader: () => void;
  showQuickSearch: () => void;
  setHeaderHeight: (headerHeight: number) => void;
  isStartPage: boolean;
  isCmsPage: boolean;
  isProductPage: boolean;
  isGiftCardPage: boolean;
  isOrderConfirmationPage: boolean;
  isStoreMode: boolean;
  usps: Usp[];
  page: Page;
};

type PropType = ConnectedPropType;

const Base = styled.div({
  height: 'inherit',
  position: 'fixed',
  zIndex: style.ZIndex.Header,
  right: '0',
  left: '0',
  ...style.transition({ property: 'transform', duration: `${250}ms`, willChange: true }),
  ...style.transition({ property: 'background', duration: `${250}ms`, willChange: false }),
});

const Placeholder = styled.header({
  display: 'flex',
  order: 0,
  pointerEvents: 'none',
});

class MainHeader extends React.Component<PropType, { y: number; isHovered: boolean; showTopLinks: boolean }> {
  unsubscribeResize: () => void;
  unsubscribeScroll: () => void;
  oldScrollPos: number = 0;

  constructor(props: PropType) {
    super(props);
    this.state = {
      y: 0,
      isHovered: false,
      showTopLinks: false,
    };
  }

  componentWillUnmount() {
    if (this.unsubscribeResize) {
      this.unsubscribeResize();
    }
    this.unsubscribeResize = null;

    if (this.unsubscribeScroll) {
      this.unsubscribeScroll();
    }
    this.unsubscribeScroll = null;
  }
  componentDidMount() {
    this.unsubscribeScroll = on('scroll', () => this.scrollListener());
    this.unsubscribeResize = on('resize', () => this.scrollListener());
    this.props.setHeaderHeight(this.calculateHeaderHeight());
  }

  componentDidUpdate(
    prevProps: Readonly<PropType>,
    prevState: Readonly<{ y: number; isHovered: boolean; showTopLinks: boolean }>,
  ): void {
    if (
      prevProps.currentBreakpoint !== this.props.currentBreakpoint ||
      prevProps.usps?.length !== this.props.usps?.length ||
      prevState.y !== this.state.y ||
      prevState.showTopLinks !== this.state.showTopLinks ||
      prevProps.modalIsOpen !== this.props.modalIsOpen
    ) {
      // We need a small delay so css transitions are started for calc the height
      setTimeout(() => this.props.setHeaderHeight(this.calculateHeaderHeight()), 80);
    }
  }

  getHeaderHeight() {
    return isCompact(this.props.currentBreakpoint) ? mobileHeaderHeight : desktopHeaderHeight;
  }

  scrollListener() {
    const { isVisible, isCollapsed, currentBreakpoint } = this.props;
    const y = scrollPosition();
    this.setState({ ...this.state, y });
    const didScrollDown = y > this.oldScrollPos;
    const didScrollUp = y < this.oldScrollPos;
    const scrollPosIsBelowHeader = y > this.getHeaderHeight();

    if (isCompact(currentBreakpoint)) {
      this.onMobileScroll(didScrollDown, didScrollUp, isVisible, scrollPosIsBelowHeader);
    } else {
      this.onDesktopScroll(didScrollDown, didScrollUp, isCollapsed, scrollPosIsBelowHeader);
    }

    this.oldScrollPos = scrollPosition();
  }

  onMobileScroll(didScrollDown: boolean, didScrollUp: boolean, isVisible: boolean, scrollPosIsBelowHeader: boolean) {
    if (didScrollDown && scrollPosIsBelowHeader && isVisible) {
      this.props.showScrollDownHeader(true);
    } else if (didScrollUp && !isVisible) {
      this.props.showScrollUpHeader();
    }
  }

  onDesktopScroll(didScrollDown: boolean, didScrollUp: boolean, isCollapsed: boolean, scrollPosIsBelowHeader: boolean) {
    if (didScrollDown && scrollPosIsBelowHeader && !isCollapsed) {
      this.props.showScrollDownHeader(false);
    } else if (didScrollUp && isCollapsed) {
      this.props.showScrollUpHeader();
    }
  }

  isMobile = () => isCompact(this.props.currentBreakpoint);
  hideUsps = () => (this.props.usps?.length ?? 0) <= 0 && !this.props.isStoreMode;

  calculateHeaderHeight = () =>
    this.isMobile()
      ? mobileHeaderHeight +
      (this.hideUsps() ? 0 : BLACK_TOP_MOBILE_HEIGHT) +
      (!this.props.quickSearchIsOpen && (this.state.y > QUICKSEARCH_HEIGHT || this.props.modalIsOpen)
        ? 0
        : QUICKSEARCH_HEIGHT)
      : this.props.isCollapsed && !this.state.isHovered
        ? COMPACT_HEIGHT
        : desktopHeaderHeight + (this.hideUsps() && !this.state.showTopLinks ? 0 : this.props.isCollapsed && !this.state.showTopLinks ? 0 : BLACK_TOP_DESKTOP_HEIGHT);

  transparentTop = () => {
    return this.props.isStartPage ||
      this.props.isCmsPage ||
      this.props.isProductPage ||
      this.props.isGiftCardPage ||
      this.props.isOrderConfirmationPage;
  }

  render() {
    const { isVisible } = this.props;
    const isMobile = this.isMobile();
    const hideUSPs = this.hideUsps();
    const marginIfNotScrolled = !hideUSPs ? BLACK_TOP_DESKTOP_HEIGHT : 0;
    const marginIfScrolled = 0;
    const isScrolledOrModalOpen =
      this.state.y > QUICKSEARCH_HEIGHT || this.props.mainMenu.isOpen || this.props.modalIsOpen || (isMobile && this.props.isProductPage);

    return (
      <Placeholder
        css={{
          pointerEvents: 'initial',
          ...(isMobile && {
            marginBottom: (hideUSPs ? 0 : BLACK_TOP_MOBILE_HEIGHT) + (isScrolledOrModalOpen ? 0 : QUICKSEARCH_HEIGHT),
          }),
          ...(!isMobile && {
            marginBottom: !hideUSPs ? marginIfNotScrolled : 0,
            ...style.transition({ property: 'margin-bottom', duration: `${250}ms`, willChange: false }),
          }),
          height: this.getHeaderHeight() + 'px',
        }}
      >
        <Base
          className="sg-header"
          css={{
            top: 0,
            ...(!isMobile && {
              marginTop:
                this.props.isCollapsed && !this.state.showTopLinks
                  ? marginIfScrolled
                  : hideUSPs && !this.state.showTopLinks
                    ? 0
                    : BLACK_TOP_DESKTOP_HEIGHT,
              ...style.transition({ property: 'margin-top', duration: `${250}ms`, willChange: false }),
            }),
            transform: !isVisible && !isMobile ? 'translateY(-100%)' : 'translateY(0)',
          }}
          onMouseEnter={() => this.setState({ ...this.state, isHovered: true })}
          onMouseLeave={() => this.setState({ ...this.state, isHovered: false })}
        >
          {isMobile ? (
            <MobileHeader
              quickSearchIsOpen={this.props.quickSearchIsOpen}
              transparentAtTop={this.props.isStartPage}
              isScrolled={isScrolledOrModalOpen} //Treat as scrolled if any modal is open
              isHovered={this.state.isHovered}
              isCollapsed={this.props.isCollapsed}
              hideUSPs={hideUSPs}
              mainMenuOpen={this.props.mainMenu.isOpen}
              isStoreMode={this.props.isStoreMode}
            />
          ) : (
            <DesktopHeader
              currentBreakpoint={this.props.currentBreakpoint}
              quickSearchIsOpen={this.props.quickSearchIsOpen}
              mainMenu={this.props.mainMenu}
              isCollapsed={this.props.isCollapsed || this.state.showTopLinks}
              pages={this.props.pages}
              showQuickSearch={this.props.showQuickSearch}
              showScrollUpHeader={this.props.showScrollUpHeader}
              transparentAtTop={this.transparentTop()}
              isScrolled={this.state.y > 0}
              isHovered={this.state.isHovered || this.state.showTopLinks}
              showTopLinks={this.state.showTopLinks}
              toggleTopLinks={() =>
                this.setState((state) => ({
                  showTopLinks: !state.showTopLinks,
                }))
              }
              isStoreMode={this.props.isStoreMode}
            />
          )}
        </Base>
        {!isMobile ? <DesktopTieredModal /> : <MobileTieredModal />}
        {!isMobile && (
          <DesktopSecondaryMenu headerHeight={this.getHeaderHeight() + marginIfNotScrolled} />
        )}
        <QuickSearchResults />
        <AddToCartModal headerHeight={this.getHeaderHeight() + marginIfNotScrolled} />
        <CartModal headerHeight={this.getHeaderHeight() + marginIfNotScrolled} />
      </Placeholder>
    );
  }
}

export default connect(
  (state) => ({
    isCollapsed: state.header.isCollapsed,
    isVisible: state.header.isVisible,
    currentBreakpoint: state.currentBreakpoint,
    quickSearchIsOpen: state.quickSearch.isOpen,
    pages: state.appShellData.pages,
    mainMenu: state.mainMenu,
    modalIsOpen: state.UI.modalIsOpen,
    isStartPage: currentPageIsStartPage(state.currentPage),
    isCmsPage: currentPageIsCmsPage(state.currentPage),
    isProductPage: currentPageIsVariation(state.currentPage),
    isGiftCardPage: currentPageIsGiftCardPage(state.currentPage),
    isOrderConfirmationPage: currentPageIsOrderConfirmation(state.currentPage),
    isStoreMode: state.appShellData.context.isStoreMode,
    usps: state.appShellData.usps,
    page: state.currentPage,
  }),
  (dispatch) => ({
    showScrollDownHeader(isMobile: boolean) {
      dispatch(showScrollDownHeader(isMobile));
    },
    showScrollUpHeader() {
      dispatch(showScrollUpHeader());
    },
    showQuickSearch() {
      dispatch(showQuickSearch());
    },
    setHeaderHeight(headerHeight: number) {
      dispatch(SetHeaderHeight(headerHeight));
    },
  }),
)(MainHeader);
